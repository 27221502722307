/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-21 09:46:53
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-13 18:01:35
 */
export const columns = [
  {
    title: '经销商名称',
    dataIndex: 'title',
    key: 'title',
    align:'center',
    width: 200,
  },
  {
    title: '小计',
    dataIndex: 'subtotal',
    key: 'subtotal',
    align:'center',
    scopedSlots: { customRender: 'subtotal' },
  },
  {
    title: '一月',
    dataIndex: 'january',
    key: 'january',
    align:'center',
    scopedSlots: { customRender: 'january' },
  },
  {
    title: '二月',
    dataIndex: 'february',
    key: 'february',
    align:'center',
    // ellipsis:true,
    scopedSlots: { customRender: 'february' },
  },
  {
    title: '三月',
    dataIndex: 'march',
    key: 'march',
    align:'center',
    scopedSlots: { customRender: 'march' },
  },
  {
    title: '四月',
    dataIndex: 'april',
    key: 'april',
    align:'center',
    // ellipsis:true
    scopedSlots: { customRender: 'april' },
  },
  {
    title: '五月',
    dataIndex: 'may',
    key: 'may',
    align:'center',
    scopedSlots: { customRender: 'may' },
  },
  {
    title: '六月',
    dataIndex: 'june',
    key: 'june',
    align:'center',
    // ellipsis:true,
    scopedSlots: { customRender: 'june' },
  },
  {
    title: '七月',
    dataIndex: 'july',
    key: 'july',
    align:'center',
    scopedSlots: { customRender: 'july' },
  },
  {
    title: '八月',
    dataIndex: 'august',
    key: 'august',
    align:'center',
    // ellipsis:true,
    scopedSlots: { customRender: 'august' },
  },
  {
    title: '九月',
    dataIndex: 'september',
    key: 'september',
    align:'center',
    scopedSlots: { customRender: 'september' },
  },
  {
    title: '十月',
    dataIndex: 'october',
    key: 'december',
    align:'center',
    // ellipsis:true,
    scopedSlots: { customRender: 'december' },
  },
  {
    title: '十一月',
    dataIndex: 'december',
    key: 'november',
    align:'center',
    scopedSlots: { customRender: 'december' },
  },
  {
    title: '十二月',
    dataIndex: 'december',
    key: 'december',
    align:'center',
    // ellipsis:true,
    scopedSlots: { customRender: 'december' },
  },
]
